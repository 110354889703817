import React from 'react';

import { ErrorPage } from '../../components/shared/ErrorPage';

const Forbidden = () => (
  <ErrorPage
    seoTitle="403: Forbidden"
    title="Stránka je tajná"
    description="Osobní skóre vám spočítáme jinde."
  />
);

export default Forbidden;
